import React, { useRef, useEffect } from 'react'
import { login, isAuthenticated } from '../../../../utils/auth'
import { Pane, TextInputField } from 'evergreen-ui'
import { createUseStyles } from 'react-jss'
import {
  navStyles,
  customListStyles,
  tableSectionStyles
} from '../../../../styles/sharedComponentStyles'
import UserDetails from '../../../../components/UserDetails'
import { useHandleUsersList } from '../../../../hooks/useHandleUsersList'
import { useGlobalStore } from '../../../../hooks/useGlobalStore'

const useSharedStyles = createUseStyles({
  ...navStyles,
  ...customListStyles,
  ...tableSectionStyles,
  usersTableAgGrid: {
    height: '93vh'
  }
})

const UsersPageNav = ({ usersSet, filterValue, handleUsersFilter }) => {
  const sharedClasses = useSharedStyles()

  return (
    <>
      <nav className={sharedClasses.nav}>
        <Pane className={sharedClasses.secondaryNav}>
          <h1 data-testid="main-title">Users</h1>
          <Pane className={sharedClasses.autoComplete} display="flex">
            {usersSet && (
              <TextInputField
                value={filterValue}
                label="Search for a User name or email"
                onChange={e => handleUsersFilter(e.target.value)}
                style={{
                  padding: '20px'
                }}
              />
            )}
          </Pane>
        </Pane>
      </nav>
    </>
  )
}

const UsersPage = ({ params }) => {
  const { setRealmPath } = useGlobalStore()
  const usersSectionRef = useRef(null)
  const {
    usersSet,
    filterValue,
    handleUserDetailsClose,
    handleUsersFilter
  } = useHandleUsersList({ usersSectionRef })

  useEffect(() => {
    setRealmPath('users')
  }, [setRealmPath])

  if (!isAuthenticated()) {
    login()
    return (
      <Pane
        display="flex"
        alignItems="center"
        justifyContent="center"
        height="100vh"
      >
        <h2>Redirecting to login...</h2>
      </Pane>
    )
  }

  return (
    <>
      <UsersPageNav
        usersSet={usersSet}
        filterValue={filterValue}
        handleUsersFilter={handleUsersFilter}
      />
      <UserDetails
        userId={params['userId']}
        parentSectionRef={usersSectionRef}
        show={true}
        handleDetailsClose={handleUserDetailsClose}
      />
    </>
  )
}

export default UsersPage
